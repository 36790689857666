import React, { Component } from 'react'
import Form from '../common/form'
import Joi from 'joi'
import { Container } from 'react-bootstrap'
import axios from 'axios'
import config from '../../config.json'
import AuthService from '../../services/authService'
import * as Yup from "yup";
import { toast } from "react-toastify";
import IconBreadcrumbs from "../custom-breadcrumb";
import { Formik } from "formik";
import Box from "@mui/material/Box";
import { Button, TextField } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";

class RegisterPage extends Form {
    apiEndPoint = config.apiEndPoint + 'users/register'

    schema = Yup.object().shape({
        username: Yup.string()
            .required("User is a required field")
            .min(4, 'Minimum is 4 characters')
            .max(15, 'Maximum is 15 digits'),
        password: Yup.string()
            .required("Password is a required field")
            .min(4, 'Minimum is 4 characters')
            .max(15, 'Maximum is 15 digits'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match'),
        email: Yup.string()
            .required('Email is a required field')
            .email('Please enter a valid Email')
    });

    recaptchaRef = React.createRef();

    async doSubmit(values, captchaResponse) {

        return axios.post(this.apiEndPoint, { ...values, captchaResponse: captchaResponse })
            .then((result) => result.data)
            .then((result) => {
                toast.success("Successfully Registered..");
                setTimeout(() => {
                    window.location = '/downloads'
                }, 5000)
            })
            .catch((ex) => ex?.response?.data)
            .then(ex => {
                toast.error(ex);
                this.recaptchaRef.current.reset();

            })
    }
    render() {
        return (
            <>
                <IconBreadcrumbs name={"Register"} />
                <Formik
                    validationSchema={this.schema}
                    initialValues={{
                        username: "",
                        password: "",
                        confirmPassword: "",
                        email: ""
                    }}
                    onSubmit={(values) => {
                        // Alert the input values of the form that we filled
                        this.doSubmit(values, this.recaptchaRef.current.getValue())
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    }) => (
                        <Container>
                            <Box style={{
                                border: '1px solid black',
                                borderRadius: '10px',
                                overflow: "hidden",
                                maxWidth: '500px'
                            }}>
                                <Box className="header">
                                    <h3 className="fw-bold p-2 text-uppercase">START PLAYING NOW</h3>
                                </Box>
                                <Box className="content">
                                    <form noValidate onSubmit={handleSubmit}>
                                        <Box className="mb-3">
                                            <TextField
                                                autoComplete="current-password"
                                                type="username"
                                                label="Username"
                                                name="username"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.username}
                                                placeholder="Enter username here"
                                                id="username"
                                                className="form-control"
                                            />
                                            {(errors.username && touched.username) && (
                                                <div className="alert alert-danger mt-3">{errors.username}</div>)}
                                        </Box>
                                        <Box className="mb-3">
                                            <TextField
                                                type="password"
                                                name="password"
                                                label="Password"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.password}
                                                placeholder="Enter password"
                                                id="password"
                                                className="form-control"
                                            />
                                            {(errors.password && touched.password) && (
                                                <div className="alert alert-danger mt-3">{errors.password}</div>)}
                                        </Box>
                                        <Box className="mb-3">
                                            <TextField
                                                type="password"
                                                name="confirmPassword"
                                                label="Confirm Password"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.confirmPassword}
                                                placeholder="Confirm your password"
                                                id="confirmPassword"
                                                className="form-control"
                                            />
                                            {(errors.confirmPassword && touched.confirmPassword) && (
                                                <div
                                                    className="alert alert-danger mt-3">{errors.confirmPassword}</div>)}
                                        </Box>
                                        <Box className="mb-3">
                                            <TextField
                                                type="email"
                                                name="email"
                                                label="Email"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.email}
                                                placeholder="Email"
                                                id="email"
                                                className="form-control"
                                            />
                                            {(errors.email && touched.email) && (
                                                <div className="alert alert-danger mt-3">{errors.email}</div>)}
                                        </Box>
                                        <Box className="mb-3">
                                            <ReCAPTCHA
                                                ref={this.recaptchaRef}
                                                sitekey={config.RECAPTCHA_SITE_KEY}
                                            ></ReCAPTCHA>
                                        </Box>
                                        <Button
                                            type="submit"
                                            variant="outlined"
                                            color="success"
                                            className="form-control"
                                        >
                                            Register Your Account
                                        </Button>
                                    </form>
                                </Box>
                            </Box>
                        </Container>
                    )}
                </Formik>
            </>
        );
    }

}

export default RegisterPage
