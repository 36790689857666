import React, {Component, useEffect, useState} from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import {Link, useNavigate, useSearchParams} from 'react-router-dom'
import RadioButton from '../common/radioButton'
import config from '../../config.json'
import axios from 'axios'
import {paginate} from '../../utils/paginate'
import Pagination from '../common/pagination'
import Box from "@mui/material/Box";
import IconBreadcrumbs from "../custom-breadcrumb";
import Button from "@mui/material/Button";

const RankingsPage = () => {
    const [state, setState] = useState({
        rankType: 'NONE',
        rankList: [],
        currentPage: 1,
        pageSize: 5,
    });

    const apiEndPoint = config.apiEndPoint + 'statistics/'
    const [searchParams, setSearchParams] = useSearchParams();
    const [rankType, setRankType] = useState();
    useEffect(async () => {
        const results = await axios.get(apiEndPoint + rankType)

        setState({
            rankType: rankType,
            rankList: results.data,
            currentPage: 1,
        })
    }, [rankType]);


    const handleChangePage = (page) => {
        setState({currentPage: page})
    }

    const {rankList, currentPage, pageSize} = state
    const navigateTo = (rankType) => {
        setRankType(rankType);
    }
    const title = () => {
        switch (rankType) {
            case 15:
            case 25:
            case 45:
            case 135:
            case 145:
                return 'Potency';
            case 2:
                return 'Money';
            case 3:
                return 'Virtue';
            case 4:
                return 'PK';
            case 5:
                return 'KO';
            case 6:
                return 'Guild';
        }
    }
    return (
        <Box>
            <IconBreadcrumbs name="Rankings"/>
            <Container className="mt-5 content mb-5 p-5 rounded-2 border-2" style={{opacity: '0.95'}}>
                <Box display={'flex'} gap={2}>
                    <Button onClick={() => navigateTo(15)} className={"mb-3"} variant={"outlined"}>Trojan</Button>
                    <Button onClick={() => navigateTo(25)} className={"mb-3"} variant={"outlined"}>Warrior</Button>
                    <Button onClick={() => navigateTo(45)} className={"mb-3"} variant={"outlined"}>Archer</Button>
                    <Button onClick={() => navigateTo(135)} className={"mb-3"} variant={"outlined"}>Water</Button>
                    <Button onClick={() => navigateTo(145)} className={"mb-3"} variant={"outlined"}>Fire</Button>
                    <Button onClick={() => navigateTo(2)} className={"mb-3"} variant={"outlined"}>Money</Button>
                    <Button onClick={() => navigateTo(3)} className={"mb-3"} variant={"outlined"}>Virtue</Button>
                    <Button onClick={() => navigateTo(4)} className={"mb-3"} variant={"outlined"}>PK</Button>
                    <Button onClick={() => navigateTo(5)} className={"mb-3"} variant={"outlined"}>KO</Button>
                    <Button onClick={() => navigateTo(255)} className={"mb-3"} variant={"outlined"}>Guilds</Button>
                </Box>
                <Row>

                    <Col xs={10}>
                        {rankList.length == 0 && (
                            <h3>There are no ranks to show in this category.</h3>
                        )}
                        {rankType !== 255 && rankList.length != 0 && (
                            <table className="table" style={{
                                color: '#fff'
                            }}>
                                <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Level</th>
                                    <th scope="col">{title()}</th>
                                    <th scope="col">Guild</th>
                                </tr>
                                </thead>
                                <tbody>
                                {rankList.map((p) => (
                                    <tr>
                                        <td>{p.name}</td>
                                        <td>{p.level}</td>
                                        <td>{p.param?.toLocaleString()}</td>
                                        <td>{p.guildName}</td>
                                    </tr>
                                ))}{' '}
                                </tbody>
                            </table>
                        )}
                        {rankType === 255 && rankList.length != 0 && (
                            <table className="table" style={{
                                color: '#fff'
                            }}>
                                <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Leader</th>
                                    <th scope="col">Fund</th>
                                    <th scope="col">Members</th>
                                </tr>
                                </thead>
                                <tbody>
                                {rankList.map((p) => (
                                    <tr>
                                        <td>{p.name}</td>
                                        <td>{p.leaderName}</td>
                                        <td>{p.fund?.toLocaleString()}</td>
                                        <td>{p.members}</td>
                                    </tr>
                                ))}{' '}
                                </tbody>
                            </table>
                        )}


                    </Col>
                </Row>
            </Container>
        </Box>
    )
}

export default RankingsPage
