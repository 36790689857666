import React, {Component} from 'react'
import Form from '../common/form'
import Joi from 'joi'
import {Container} from 'react-bootstrap'
import * as Yup from "yup";
import axios from "axios";
import {toast} from "react-toastify";
import AuthService from "../../services/authService";
import IconBreadcrumbs from "../custom-breadcrumb";
import {Formik} from "formik";
import Box from "@mui/material/Box";
import {Button, TextField} from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import config from "../../config.json";

class ForgotPassword extends Form {
    apiEndPoint = config.apiEndPoint + 'users/forgotpass'
    recaptchaRef = React.createRef();
    schema = Yup.object().shape({
        username: Yup.string()
            .required("User is a required field")
            .min(4, 'Minimum is 4 characters')
            .max(15, 'Maximum is 15 digits'),
        email: Yup.string()
            .required("Email is a required field")
            .email('Invalid Email')
    });

    async doSubmit(values, captchaResponse) {

        return axios.post(this.apiEndPoint, {...values, captchaResponse: captchaResponse})
            .then((result) => result.data)
            .then((result) => {
                console.log(result);
                const token = result?.jwt

                if (!token) {
                    toast.error("Something went wrong.");
                    return;
                }
                AuthService.storeJWT(token)
                toast.success("Login successful.. Redirecting");
                setTimeout(() => {
                    window.location = '/'
                }, 1500)
            })
            .catch((ex) => ex?.response?.data)
            .then(ex => {
                toast.error(ex);
                this.recaptchaRef.current.reset();

            })
    }

    render() {
        return (
            <>
                <IconBreadcrumbs name={"Forgot Password"}/>
                <Formik
                    validationSchema={this.schema}
                    initialValues={{username: "", email: ""}}
                    onSubmit={(values) => {
                        // Alert the input values of the form that we filled
                        this.doSubmit(values, this.recaptchaRef.current.getValue())
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                      }) => (
                        <Container>
                            <Box style={{
                                border: '1px solid black',
                                borderRadius: '10px',
                                overflow: "hidden",
                                maxWidth: '500px'
                            }}>
                                <Box className="header">
                                    <h3 className="fw-bold p-2 text-uppercase">Forgot Password</h3>
                                </Box>
                                <Box className="content">
                                    <form noValidate onSubmit={handleSubmit}>
                                        <Box className="mb-3">
                                            <TextField
                                                autoComplete="current-password"
                                                type="username"
                                                label="Username"
                                                name="username"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.username}
                                                placeholder="Enter username here"
                                                id="username"
                                                className="form-control"
                                            />
                                            {(errors.username && touched.username) && (
                                                <div className="alert alert-danger mt-3">{errors.username}</div>)}
                                        </Box>
                                        <Box className="mb-3">
                                            <TextField
                                                type="email"
                                                name="email"
                                                label="Email"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.email}
                                                placeholder="Enter email"
                                                id="email"
                                                className="form-control"
                                            />
                                            {(errors.email && touched.email) && (
                                                <div className="alert alert-danger mt-3">{errors.email}</div>)}
                                        </Box>
                                        <Box className="mb-3">
                                            <ReCAPTCHA
                                                ref={this.recaptchaRef}
                                                sitekey={config.RECAPTCHA_SITE_KEY}
                                            ></ReCAPTCHA>
                                        </Box>
                                        <Button
                                            type="submit"
                                            variant="outlined"
                                            color="success"
                                            className="form-control"
                                        >
                                            Send Recovery link
                                        </Button>
                                    </form>
                                </Box>
                            </Box>
                        </Container>
                    )}
                </Formik>
            </>
        );
    }

}

export default ForgotPassword
