import React, {Component} from 'react'
import Form from '../common/form'
import Joi from 'joi'
import {Container} from 'react-bootstrap'
import axios from 'axios'
import config from '../../config.json'
import AuthService from '../../services/authService'
import * as Yup from "yup";
import {toast} from "react-toastify";
import IconBreadcrumbs from "../custom-breadcrumb";
import {Formik} from "formik";
import Box from "@mui/material/Box";
import {Button, TextField} from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";

class ChangePassword extends Form {
    apiEndPoint = config.apiEndPoint + 'active-user/changepass'

    schema = Yup.object().shape({
        oldPassword: Yup.string()
            .required("Password is a required field")
            .min(4, 'Minimum is 4 characters')
            .max(15, 'Maximum is 15 digits'),
        newPassword: Yup.string()
            .required("Password is a required field")
            .min(4, 'Minimum is 4 characters')
            .max(15, 'Maximum is 15 digits'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
    });

    recaptchaRef = React.createRef();

    async doSubmit(values, captchaResponse) {
        return axios.post(this.apiEndPoint, {...values, captchaResponse: captchaResponse})
            .then((result) => result.data)
            .then((result) => {
                const token = result?.token

                if (!token) {
                    toast.error("Something went wrong.");
                    return;
                }
                AuthService.storeJWT(token)
                toast.success("Password changed successfully");
                setTimeout(() => {
                    window.location = '/'
                }, 1500)
            })
            .catch((ex) => ex?.response?.data)
            .then(ex => {
                toast.error(ex);
                this.recaptchaRef.current.reset();

            })
    }

    render() {
        return (
            <>
                <IconBreadcrumbs name={"Change Password"}/>
                <Formik
                    validationSchema={this.schema}
                    initialValues={{
                        oldPassword: "",
                        newPassword: "",
                        confirmPassword: "",
                    }}
                    onSubmit={(values) => {
                        // Alert the input values of the form that we filled
                        this.doSubmit(values, this.recaptchaRef.current.getValue())
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                      }) => (
                        <Container>
                            <Box style={{
                                border: '1px solid black',
                                borderRadius: '10px',
                                overflow: "hidden",

                                maxWidth: '500px'
                            }}>
                                <Box className="header">
                                    <h3 className="fw-bold p-2 text-uppercase">Change Password</h3>
                                </Box>
                                <Box className="content">
                                    <form noValidate onSubmit={handleSubmit}>
                                        <Box className="mb-3">
                                            <TextField
                                                type="password"
                                                name="oldPassword"
                                                label="Old Password"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.oldPassword}
                                                placeholder="Enter your old password"
                                                id="oldPassword"
                                                className="form-control"
                                            />
                                            {(errors.oldPassword && touched.oldPassword) && (
                                                <div className="alert alert-danger mt-3">{errors.oldPassword}</div>)}
                                        </Box>
                                        <Box className="mb-3">
                                            <TextField
                                                type="password"
                                                name="newPassword"
                                                label="New Password"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.newPassword}
                                                placeholder="Enter your new password"
                                                id="newPassword"
                                                className="form-control"
                                            />
                                            {(errors.newPassword && touched.newPassword) && (
                                                <div className="alert alert-danger mt-3">{errors.newPassword}</div>)}
                                        </Box>
                                        <Box className="mb-3">
                                            <TextField
                                                type="password"
                                                name="confirmPassword"
                                                label="Confirm Password"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.confirmPassword}
                                                placeholder="Confirm your password"
                                                id="confirmPassword"
                                                className="form-control"
                                            />
                                            {(errors.confirmPassword && touched.confirmPassword) && (
                                                <div
                                                    className="alert alert-danger mt-3">{errors.confirmPassword}</div>)}
                                        </Box>
                                        <Box className="mb-3">
                                            <ReCAPTCHA
                                                ref={this.recaptchaRef}
                                                sitekey={config.RECAPTCHA_SITE_KEY}
                                            ></ReCAPTCHA>
                                        </Box>
                                        <Button
                                            type="submit"
                                            variant="outlined"
                                            color="success"
                                            className="form-control"
                                        >
                                            Change Password
                                        </Button>
                                    </form>
                                </Box>
                            </Box>
                        </Container>
                    )}
                </Formik>
            </>
        );
    }


}

export default ChangePassword
