import axios from "axios";
import {toast} from "react-toastify";
import {Navigate, useNavigate} from "react-router-dom";
import React from "react";
import AuthService from "./authService";

export const useInterceptor = () => {
    const navigate = useNavigate();
    axios.interceptors.response.use((response) => {
        return response;
    }, (error) => {
        const response = error?.response
        if (response && response.status === 401) {
            AuthService.clearUserInformation();
            toast.error('Please relog.');
            navigate('/user/login')
            return;
        }
        return Promise.reject(error);
    });

    axios.interceptors.request.use(function (config) {
        const token = AuthService.getJwt();
        if (token)
            config.headers.Authorization = token;
        return config;
    });
};