import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";

export const BoxItem = ({text}: { text: string }) => {
    return <Box style={{
        display: "flex",
        padding: 20,
        borderRadius: '5px',
        marginBottom: 40,
        backgroundColor: 'rgba(255, 255, 255, 0.07)',
        cursor: 'pointer',
    }}>
        <Typography variant={'h6'} >{text}</Typography>
    </Box>
}