import React, {Component} from 'react'

const Input = ({name, label, error, ...rest}) => {
    return (
        <div className="mb-3">
            <label htmlFor={name} className="form-label mb-2">
                <h5>{label}</h5>
            </label>
            <input
                id={name}
                name={name}
                placeholder={label}
                {...rest}
                className="form-control"
            />
            {
                // {...rest} extracts the types without adding them manually.
            }
            {error && <div className="alert alert-danger mt-3">{error}</div>}
        </div>
    )
}
export default Input
