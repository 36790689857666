import {ItemsImages} from "./items-list";

export const findClassName = (jobId) => {

    if (jobId >= 11 && jobId <= 15) return "Trojan";
    if (jobId >= 21 && jobId <= 15) return "Warrior";
    if (jobId >= 41 && jobId <= 15) return "Archer";
    if (jobId >= 100 && jobId <= 105) return "Taoist";
    if (jobId >= 131 && jobId <= 135) return "WaterTaoist";
    if (jobId >= 141 && jobId <= 145) return "FireTaoist";
    return 'Unknown';
}

export const fetchItemImage = (itemId) => {
    const itemPath = ItemsImages.get(itemId.toString());
    if (!itemPath) {
        const secondTrial = ItemsImages.get('3' + itemId.toString())
        if(secondTrial)
            return `/items/${secondTrial}`;
        return `/items/default.png`;
    }
    return `/items/${itemPath}`;
}