import Box from "@mui/material/Box";
import IconBreadcrumbs from "../custom-breadcrumb";
import { Divider, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Container } from "react-bootstrap";
import marketMap from '../../assets/market.jpg'
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { ItemsImages } from "../../utils/items-list";
import config from "../../config.json";

export const MarketPlacePage = () => {

    const apiEndPoint = config.apiEndPoint + 'info/marketplace'
    const [result, setResult] = useState([]);
    const [enabled, setEnabled] = useState(false);
    const [posXMg, setPosXMg] = useState(0)
    const [posYMg, setPosYMg] = useState(0)
    const imgRef = useRef();
    useEffect(() => {
        axios.get(apiEndPoint)
            .then((response) => response.data)
            .then(data => {
                setResult(data);
            });
    }, [])
    const renderFilterOptions = () => {
        return (<></>)
    };
    const rotatePosition = (x, y) => {
        //Specific for market only
        const newX = (x - y) * 32 + 8192 / 2;
        const newY = ((x + y) * 1) + (8192 / 10);
        return [newX, newY];
    }
    const mapWidth = 512;
    const mapHeight = 512;
    const hoverItem = (item) => {
        console.log('item=', item)
        setEnabled(true)
        let posX = parseInt(item.positionX);
        let posY = parseInt(item.positionY);


        const newX = (posX - posY) * 32 + 8192 / 2 / 1;
        const newY = (posX + posY - (384 - 1)) * (32 / 2) + 8192 / 2;


        posX = newX * 0.0625;
        posY = newY * 0.0625;

        // var imgElem = $("#marketImg");
        console.log('w=', imgRef.current.width, ',h=', imgRef.current.height, 'posX=', posX, ',posY=', posY);
        var elemWidth = imgRef.current.width;
        var elemHeight = imgRef.current.height;

        var scaleWidth = elemWidth / mapWidth;
        var scaleHeight = elemHeight / mapHeight;

        posX *= scaleWidth;
        posY *= scaleHeight;


        console.log('w=', imgRef.current.width, ',h=', imgRef.current.height, 'posX2=', posX, ',posY2=', posY);

        //Calculated size of the indictator element
        posX -= 12.5;
        posY -= 12.5;

        setPosXMg(posX);
        setPosYMg(posY);
        // this.hoverCircleStyle.marginLeft = posX + 'px';
        // this.hoverCircleStyle.marginTop = posY + 'px';
    };
    const array = []
    const renderItems = () => {
        return (
            <Box style={{
                maxHeight: '50vh',
                overflowY: 'auto'
            }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow className={"header fw-bold"}>
                                <TableCell className="fw-bold" style={{ color: 'white' }}>Name</TableCell>
                                <TableCell className="fw-bold" style={{ color: 'white' }}>Quality</TableCell>
                                <TableCell className="fw-bold" style={{ color: 'white' }}>Plus</TableCell>
                                <TableCell className="fw-bold" style={{ color: 'white' }}>Gem1</TableCell>
                                <TableCell className="fw-bold" style={{ color: 'white' }}>Gem2</TableCell>
                                <TableCell className="fw-bold" style={{ color: 'white' }}>Seller</TableCell>
                                <TableCell classNameب="fw-bold" style={{ color: 'white' }}>Price</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {result?.map((item) => (
                                <TableRow
                                    key={item.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    onMouseEnter={() => hoverItem(item)}
                                    onMouseLeave={() => setEnabled(false)}
                                >
                                    <TableCell>
                                        <img style={{
                                            width: 32,
                                            height: 32,
                                            maxWidth: 32,
                                            maxHeight: 32,
                                        }}
                                            className="me-1"
                                            src={`/items/${ItemsImages.get(item.itemId?.toString()) ?? ItemsImages.get('Default')}`} />{item?.itemName}
                                    </TableCell>
                                    <TableCell>{item?.quality && item.quality != '' ? item.quality : 'Fixed'}</TableCell>
                                    <TableCell>{item?.plus}</TableCell>
                                    <TableCell>{item?.gem1}</TableCell>
                                    <TableCell>{item?.gem2}</TableCell>
                                    <TableCell>{item?.seller}</TableCell>
                                    <TableCell>{item?.price.toLocaleString()}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>)
    };
    const renderMap = () => {
        return (
            <Box style={{
                position: 'relative'
            }}>
                {enabled && <div style={{
                    position: 'absolute',
                    marginTop: posYMg,
                    marginLeft: posXMg,
                }}>
                    <div className="ring-container">
                        <div className="ringring"></div>
                        <div className="circle"></div>
                    </div>
                </div>}
                <img ref={imgRef} src={marketMap} className={"img-fluid"}></img>
            </Box>
        )
    };
    return (
        <Box>
            <IconBreadcrumbs name="Marketplace" />
            <Container style={{
                maxWidth: '90%'
            }}>
                <Grid container spacing={4}>
                    <Grid item md={8}>
                        <h4 className="text-uppercase">Items</h4>
                        <Divider />
                        {renderItems()}
                    </Grid>
                    <Grid item md={4}>
                        <h4 className="text-uppercase">Map</h4>
                        <Divider />
                        <Box>
                            {renderMap()}
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );

};