import jwtDecode from 'jwt-decode'

const keyJWT = 'JWT'

const AuthService = {
    storeJWT(token) {
        localStorage.setItem(keyJWT, token)
    },

    getJwt() {
        return localStorage.getItem(keyJWT)
    },

    clearUserInformation(token) {
        localStorage.clear();
    },

    getUserFromToken() {
        const token = this.getJwt()
        if (token) return jwtDecode(token)
    },

    isUserLoggedIn() {
        const token = this.getJwt();
        if (token == null)
            return false;

        let decodedToken = jwtDecode(token);
        console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            return false;
        }
        return true;
    }
}

export default AuthService
