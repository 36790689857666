import line from '../../assets/line.png';
import { Box } from "@mui/material";
export const SectionDivider = () => {
    return <Box style={{
        background: `url('${line}')`,
        backgroundRepeat: 'no-repeat',
        marginTop: '20px',
        marginBottom: '50px',
        width: '200px',
        zIndex: 10,
        height: '40px',
        margin: '0 auto',
    }}></Box >
}