/* eslint-disable eqeqeq */
import React, {Component} from 'react'
import config from '../../config.json'
import axios from 'axios'
import {Masthead} from "../masthead";
import {CharactersCards} from "../characters-cards";
import {DiscordWidget} from "../discord-widget";
import Box from "@mui/material/Box";
import {ServerNews} from "../server-news";
import {HomeCards} from '../home-cards';

class Home extends Component {
    state = {
        onlinePlayers: 'LOADING',
        serverStatus: 'LOADING',
        lastGuildWarWinner: 'LOADING',
        lastTwinCityWinner: 'LOADING',
        lastCounterClockWinner: 'LOADING',
    }

    apiEndPoint = config.apiEndPoint + 'statistics'

    async componentDidMount() {
        try {
            const result = await axios.get(this.apiEndPoint)
            if (result.status == 200) {
                const data = result.data
                if (data?.serverOnline) {
                    this.setState({
                        serverStatus: 'ONLINE',
                        onlinePlayers: data?.onlinePlayers ?? 0
                    })
                } else {
                    this.setState({
                        serverStatus: 'OFFLINE',
                        onlinePlayers: 0
                    })
                }
            }
        } catch {
        }
    }


    render() {
        return (
            <Box style={{paddingBottom: 50}}>
                <HomeCards/>
            </Box>

        )
    }
}

export default Home
